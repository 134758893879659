<template>
  <div id="Faqs">
    <div class="container">
      <Component
        v-for="(component, index) in components"
        v-bind:key="index"
        v-bind:is="component.name"
        v-bind:props="component.props" />
      <a
        href="javascript: void(0);"
        class="btn btn-icon btn-primary back-to-top"
        id="back-to-top"
        v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
      </a>
    </div>
  </div>
</template>

<script>
import { ArrowUpIcon } from "vue-feather-icons";
export default {
  metaInfo: {
    title: "Preguntas frecuentes"
  },
  components: {
    ArrowUpIcon,
  },
  computed: {
    components() {
      return this.$store.getters["system/pages"].find(
        (page) => page.name == "Faqs"
      ).children;
    },
  },
};
</script>